// pages/index.js
import Homepage from 'src/components/home/Homepage.jsx';
import { Layout as UserLayout } from "src/layouts/userlayout/layout";
function Page() {
  return (
  <div className='agricare-container'>
    <Homepage />
  </div>
  )
}

Page.getLayout = (page) => (
  <UserLayout>
    {page}
  </UserLayout>
);

export default Page;