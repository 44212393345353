import PropTypes from 'prop-types';
import NextLink from 'next/link';
import { Logo } from 'src/components/logo';
import Footer from 'src/components/Common/Footer';
import { useEffect, useState } from 'react';
import { authActions } from '/store/auth/auth-slice';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import LoaderScreen from 'src/components/Common/LoaderScreen';

// TODO: Change subtitle text

export const Layout = (props) => {
  const [loading,isLoading]=useState(true);
  const { children } = props;
  const dispatch = useDispatch();
  let accessToken = '';

  useEffect(()=>{
    if (localStorage.getItem('isLoggedIn')) {
      dispatch(authActions.setIsLoggedIn(localStorage.getItem('isLoggedIn')));
    }

   if (localStorage.getItem('user')) {
  const user = JSON.parse(localStorage.getItem('user'));
  dispatch(authActions.setUser(user));
}
    if (typeof window !== 'undefined') {
      accessToken = localStorage.getItem('accessToken');
    }
    if (accessToken) {
      axios.defaults.headers.common = { Authorization: accessToken };
      dispatch(authActions.setAccessToken(accessToken));
    }
    isLoading(false)
  })

  if(loading) {
    return(   <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
      }}
    >
      <LoaderScreen />
    </div>)
  }
  return (
   <>
   <main>
   {children}
   </main>
   <Footer/>
   </>
  );
};

Layout.prototypes = {
  children: PropTypes.node
};



// toast.success("Logged In Successfully!");
// localStorage.setItem("accessToken", response.data.token);
// localStorage.setItem("user", JSON.stringify(response.data.user));
// localStorage.setItem("isLoggedIn", true);
// dispatch(authActions.setAccessToken(response.data.token));
// dispatch(authActions.setUser(response.data.user));
// dispatch(authActions.setIsLoggedIn(true));